<template>
    <div>
        <div v-if="paymentMode == false">
            <div class="row">
                <div class="text-center col-auto">
                    <font-awesome-icon  icon="fa-solid fa-arrow-left" @click="goBack()" />
                </div>
                <div class="text-center col font-weight-bold">Masukan Nominal Donasi</div>
                <div class="text-center col-auto"></div>
            </div>
            
            <div class="row mt-4">
                <div class="col">
                    <div class="row mt-3 border p-3" :class="filled.amount == option.value ? 'option-active' : ''" v-for="option in options" :key="option.value" @click="pickOption(option.value)">
                        <div class="col">
                            <span class="font-weight-bold">
                                {{ option.text }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row mt-4 border p-1">
                <div class="col">
                    <div class="font-weight-bold">
                        Nominal Donasi Lainnya
                    </div>
    
                    <div class="mt-2">
                        <input placeholder="Rp. 1.000.000" type="text" class="form-control" v-model="filled.amount">
                    </div>
    
                    <div class="text-muted mt-2 font-donation-small">
                        Minimal Donasi Rp. 10.000
                    </div>
    
                    <div class="mt-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="filled.anonym" id="flexCheckIndeterminate">
                            <label class="form-check-label" for="flexCheckIndeterminate">
                                Sebut "Hamba Allah" pada donasi saya
                            </label>
                        </div>
                    </div>
    
                    <div class="mt-4">
                        <div class="form-group">
                            <label for="">Doa Yang Ingin Anda Utarakan</label>
                            <textarea name="doa" id="doa" cols="30" rows="5" class="form-control" v-model="filled.doa"></textarea>
                        </div>
                    </div>
                </div>
            </div>
    
            <br>
            <br>
            <br>
    
            <div class="row fixed-bottom p-2 bg-white" style="border-top: 2px solid #fefefe;">
                <div class="col">
                    <button @click="fundNow()" class="btn btn-block text-white py-2" style="background-color: #00BF99;">Lanjut Pembayaran</button>
                </div>
            </div>
            
        </div>
        <div v-else>
            <div style="margin-top: 400px;">
                <vue-element-loading active spinner="bar-fade-scale" color="#31a05f" />
                <div class="text-center">
                    Mengarahkan ke halaman pembayaran
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faArrowLeft)
import doaAsset from '../../assets/doa.png';
import axios from 'axios';


export default {
    name: 'CampaignDetail',

    methods: {
        pickOption(value) {
            console.info(value);
            this.filled.amount = value;
        },

        goBack() {
            this.$router.go(-1);
        },

        fundNow() {
            const amount = this.filled.amount;
            this.paymentMode = true;

            // hit api transaction
            axios.post(process.env.VUE_APP_API + 'donation/' + this.$route.params.slug, {
                amount: amount,
                anonym: this.filled.anonym,
                doa: this.filled.doa
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((res) => {
                const data = res.data.data;

                this.paymentData.paymentLink = data.payment_data.payment_url;
                this.paymentData.paymentToken = data.payment_data.token;

                this.paymentMode = false;
                window.location = this.paymentData.paymentLink;
            });
        }
    },
    
    data() {
        return {
            doaAsset,

            filled: {
                amount: null,
                anonym: false,
                doa: null,
            },
            
            paymentMode: false,
            paymentData: {
                paymentLink: '',
                paymentToken: '',
            },

            isButtonActive: '',

            options: [
                {
                    text: '😊  Rp. 30.000',
                    value: 30000,
                },
                {
                    text: '😇  Rp. 100.000',
                    value: 100000,
                },
                {
                    text: '🤩  Rp. 500.000',
                    value: 500000,
                },
                {
                    text: '🥰  Rp. 2.000.000',
                    value: 2000000,
                },
            ]
        }
    }
}

</script>

<style>
.live__scroll {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.live__scroll .row {
    display: block;
}

.live__scroll .live__scroll--box {
    display: inline-block;
    float: none;
}

.option-active {
    background-color: #5fe1c7;
}
</style>